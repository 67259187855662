import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import styles from './RequestElement.module.css';
import {pushQueryElementInStr} from '../../store/queryElementsInStrSlice';
import {deleteQueryElementsInStr} from '../../store/queryElementsInStrSlice';
import classNames from "classnames";

export default function RequestElement({children, inString = false, inTagsViewer = false, identify, index}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleClickOnReqEl(e) {
        e.stopPropagation();
        if (inString || inTagsViewer) {
            return;
        }
        dispatch(pushQueryElementInStr(children, identify, index));
    }

    function handleClickOnCloseButton(e) {
        e.stopPropagation();
        dispatch(deleteQueryElementsInStr(index));
        navigate("/");
    }

    return (
        <div
            className={classNames({[styles.req_el]: true, [styles.clickable]: !inTagsViewer && !inString})}
            onClick={handleClickOnReqEl}
        >
            <div className={styles.text}>{children}</div>
            {inString &&
                <div className={styles.wrap_close_button} onClick={handleClickOnCloseButton}>
                    <img className={styles.close_button} src={process.env.PUBLIC_URL + "/img/close_button.svg"}
                         alt='Кнопка удаления плитки'/>
                </div>}
        </div>
    );
}