import styles from './TagsViewerOnPage.module.css';
import RequestElement from "../RequestElement/RequestElement";
import Divider from "@mui/material/Divider";
import {useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function TagsViewerOnPage({tags, query_data}) {

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleExpandClick = () => {
        setIsCollapsed(false);
    };

    const content = tags.map((tags_el, index) => {
        const tables = query_data.map(query_data_el => {
            let elements = tags_el[query_data_el.hintTextEn];
            elements = elements.filter((el) => el != "Не знаю");
            const hintText = query_data_el.hintTextRu;
            return (
                <div className={styles.questionWrapper}>
                    <h3 className={styles.text}>{hintText.replace('_', ' ')}</h3>
                    <div className={styles.tags}>
                        {elements.map((el) => <RequestElement key={el} inTagsViewer={true}>{el}</RequestElement>)}
                    </div>
                </div>
            );
        });

        return (
            <>
                {index !== 0 && <Divider style={{margin: '1.2rem 0'}}/>}
                <div className={styles.tableWrapper}>

                    {tables}
                </div>
            </>
        );
    });

    const overlayedContent = content.length > 1 && isCollapsed ?
        <>
            <div className={styles.overlay}>
                <button className={styles.expandButton} onClick={handleExpandClick}><KeyboardArrowDownIcon/>Раскрыть</button>
            </div>
            {content[0]}
        </>
        :
        content;

    return (
        <div className={styles.Wrapper}>
            {overlayedContent}
        </div>
    );
}